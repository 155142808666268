body {
	display: block;
	margin-left: 10%;
	margin-right: 20%;
	background-color: black;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: white;
	inline-size: 60%;
	/* width: 80%; */
	overflow-wrap: break-word;
	padding: 1em;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.access_codes {
	width: 100%;
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.spotButton {
	border-radius: 8px;
	border: 3px solid black;
	padding: 0.6em 1.2em;
	font-size: 0.8em;
	font-weight: 500;
	font-family: inherit;
	background-color: #3aaa52;
	cursor: pointer;
	transition: border-color 0.25s;
}
.spotButton:hover {
	border-color: #6fff6f;
	background-color: #238338;
	color: rgb(255, 255, 255);
}
.spotButton:focus,
.spotButton:focus-visible {
	outline: 4px auto -webkit-focus-ring-color;
}

.login {
	background-color: aqua;
	margin: 5%;
	padding: 5%;
}

.login img {
	padding: 1%;
	width: 40%;
	height: min-content;
}

.dashboard {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-content: center;
	flex-wrap: nowrap;
}

.grid {
	display: grid;
	background-color: aqua;
	grid-template-columns: 4fr 2fr;
	grid-template-rows: 1fr;

	padding: 1%;
	height: min-content;
	border: 1px solid rgb(255, 255, 255);
}
.grid > * {
	border: 1px solid rgb(255, 255, 255);
	padding: 1vw;
	overflow: hidden;
	background-color: black;
}

#user_box {
	width: 100%;
}

.grid img {
	width: min-content;
}
.playListModule {
	width: 100%;
	display: grid;
	grid-template-columns: 20% 80%;
	height: 1000px;
	border: 1px solid white;
}
.playlists-grid {
	justify-content: space-evenly;
	align-items: center;
	justify-items: stretch;
	align-content: space-around;
}

.playlists-grid figure {
	border: 1px solid white;
	font-size: 1vw;
	/* display: flex; */
	margin-block-start: 0.1vw;
	margin-block-end: 0.1vw;
	margin-inline-start: 0.1vw;
	margin-inline-end: 0.1vw;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	align-content: center;
	flex-wrap: nowrap;
}

.playlists-grid figure:hover {
	border: 1px solid green;
}

.playlistCoverPhoto {
	width: auto;
	height: 10vw;
}

.selected {
	display: none;
}

.activePlaylist {
	display: block;
	position: relative;
	height: inherit;
	border: 1px solid red;
	overflow-y: scroll;
	overflow-x: hidden;
}

.songBox {
	display: grid;
	/* grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	 */
	grid-template-columns: 3em 3em 1.5fr repeat(2, 1fr) 0.35fr;
	border: 1px solid red;
	/* align-content: center; */
	/* justify-content: center; */
	/* justify-items: center; */
	align-items: center;
	height: 3em;
}

.songBox > * {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	padding-left: 1em;
}

.songBox img {
	height: 2em;
}

#analysis {
	border: 1px solid white;
}

.analysisModule {
	border: 1px solid blue;
	display: grid;
	grid-template-columns: 2fr 1fr;
	justify-content: center;
	align-items: end;
}

.analysisModule > * {
	text-overflow: ellipsis;

	overflow: hidden;
	padding-left: 1em;
}

.playlistGraph {
	display: flex;
	flex-direction: column;
	/* height: 25vw; */
}

.playlistDropDownMenu {
	display: flex;
	flex-direction: right;
}

.radialGraph {
	display: flex;
	flex-direction: column;
	height: 25vw;
}
